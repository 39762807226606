////////////////
// Every page //
////////////////

import $ from 'jquery';

// These modules are always used
import { NavLoad } from 'App/nav/load';
import { Social as SocialShare } from 'App/social/share';
import { Cookie } from 'App/cookie/cookie';
import { BackToTop } from 'App/back-to-top/back-to-top';

NavLoad.init();
SocialShare.init();
Cookie.init();
BackToTop.init();


// Publish resize and scroll events for other modules to use
import { publish } from 'Util/pubsub';
import { throttle } from 'Util/throttle';

const $window = $(window);
$window.on('resize', throttle(() => publish('/window/resize'), 250));
$window.on('scroll', () => publish('/window/scroll'));


// These modules are used often enough, or are small enough, that it's worth always loading them
import { expandCollapse } from 'App/expand-collapse/expand-collapse';
import { lightbox } from 'App/lightbox/lightbox';
import { MapLoad } from 'App/map/map.load';
import { Rotator } from 'App/rotator/rotator';
import { selectLink } from 'App/select-link/select-link';
import 'tablescroll';
import { VideoGroupLoad } from 'App/video-group/video-group';

if (document.querySelector('.js-expand-collapse')) {
	expandCollapse.init();
}

// Lightbox
if (document.querySelector('.js-lightbox-single, .js-lightbox-gallery')) {
	lightbox.init();
}

// Maps
const $maps = $('.js-map');
if ($maps.length) {
	MapLoad.init($maps);
}

// Rotators
const $rotators = $('.js-rotator');
if ($rotators.length) {
	Rotator.init($rotators);
}

// Select Link
if (document.querySelector('.js-select-link')) {
	selectLink.init();
}

// Table Scroll
// Check DOM for elements requiring JS
const $tables = $('.c-rte').find('table');
if ($tables.length) {
	$tables.tableScroll();
}

// Video Group
if (document.querySelector('.js-video-group')) {
	VideoGroupLoad.init();
}


//////////////////////////////////
// Any page, but not every page //
//////////////////////////////////

// Modal
if (document.querySelector('.js-modal')) {
	import(/* webpackChunkName: "Modal" */ 'App/modal/modal').then(({ modal }) => {
		modal.init({
			onShow: function ($modal) {
				var $rotators = $modal.find('.js-rotator');
				var thisFlick;
				$rotators.each(function (i, el) {
					thisFlick = $(el).data('flickity-instance');

					if (thisFlick) {
						thisFlick.resize();
					}
				});
			}
		});
	});
}

// Scroll Appear
if (document.querySelector('.js-scroll-appear')) {
	import(/* webpackChunkName: "ScrollAppear" */ 'App/scroll-appear/scroll-appear').then(({ scrollAppear }) => {
		scrollAppear.init();
	});
}

// Umbraco Forms
const $umbracoForms = $('.js-umbraco-form');
if ($umbracoForms.length) {
	import(/* webpackChunkName: "UmbracoForms" */ 'App/form/form.umbraco').then(({ UmbracoForms }) => {
		UmbracoForms.init();
	});
}

// Video Player
if (document.querySelector('.js-video-player')) {
	import(/* webpackChunkName: "VideoPlayer" */ 'App/video/video.player').then(({ VideoPlayer }) => {
		VideoPlayer.init();
	});
}

// Video Gallery
if (document.querySelector('.js-video-gallery')) {
	import(/* webpackChunkName: "VideoGallery" */ 'App/video/gallery').then(({ videoGallery }) => {
		videoGallery.init();
	});
}

// Event calendar add
if (document.querySelector('.js-calendar-link')) {
	import(/* webpackChunkName: "Calendar" */ 'App/event/calendar').then(({ Calendar }) => {
		Calendar.init();
	});
}