/* app/ui/map/map.control */

import $ from 'jquery';
import { subscribe } from 'Util/pubsub';

var $maps;

var dataAttributes = Object.freeze({
	lat: 'data-lat',
	lng: 'data-lng',
	latLng: 'data-latlng',
	zoom: 'data-zoom'
});

var MapControl = {
	init: function($elms) {
		$maps = $elms.filter('[data-map-type="control"]');

		MapControl._initSubscriptions();
	},

	_initSubscriptions: function () {
		subscribe('/map/apiLoaded', MapControl._processMaps);
	},

	_processMaps: function () {

		for (var i = 0; i < $maps.length; i++) {
			var mapCanvas = $maps[i];
			var $thisMap = $maps.eq(i);

			var zoom = parseInt($thisMap.attr(dataAttributes.zoom), 10) || 16;

			var latitude = parseFloat($thisMap.attr(dataAttributes.lat));
			var longitude = parseFloat($thisMap.attr(dataAttributes.lng));

			// Sometimes data-lat and data-lng aren't there, but data-latlng is
			var coords = $thisMap.attr(dataAttributes.latLng);
			coords = coords.split(',');
			coords = coords.map(function (el) {
				return parseFloat(el.trim());
			});

			var latLng;
			if (latitude && longitude) {
				latLng = new google.maps.LatLng(latitude, longitude);
			} else {
				latLng = new google.maps.LatLng(coords[0], coords[1]);
			}

			var mapOptions = {
				zoom: zoom || 12,
				center: latLng,
				draggable: !window.matchMedia('(pointer: coarse)').matches,
				streetViewControl: false,
				scrollwheel: false,
				mapTypeControl: true,
				panControl: false,
				panControlOptions: {
					position: google.maps.ControlPosition.LEFT_CENTER
				},
				zoomControl: true,
				zoomControlOptions: {
					style: google.maps.ZoomControlStyle.LARGE,
					position: google.maps.ControlPosition.RIGHT_BOTTOM
				}
			};

			var map = new google.maps.Map(mapCanvas, mapOptions);

			var marker = new google.maps.Marker({
				position: latLng,
				map: map
			});
		}
	}
};

export { MapControl };
